<template>
  <div ref="combobox" class="w-full relative">
    <div class="flex flex-col">
      <div
        class="cloud_input overflow-x-auto whitespace-nowrap border border-gray-300 rounded-md p-2"
        contenteditable="true"
        data-placeholder="--Enter Level Details--"
        @click="isOpen = true"
      >
        <div class="w-full flex flex-wrap gap-2">
          <span
            v-for="(tag, index) in selectedTags"
            :key="index"
            class="tag flex items-center bg-blue-200 text-blue-800 rounded-full px-3 py-1"
            contenteditable="false"
          >
            {{ tag.name }}
            <span
              class="remove-tag cursor-pointer ml-2 text-red-500"
              @click.stop="removeTag(tag)"
            >
              x
            </span>
          </span>
        </div>
      </div>
    </div>
    <!----<c-text
      placeholder="--Enter Level Details--"
      variant="w-full h-10"
      style="height: 40px !important"
      v-model="searchQuery"
      @input="[(isOpen = true), filterOptions]"
    />

    Dropdown Menu -->
    <div
      v-if="isOpen"
      class="absolute z-10 w-full bg-white border border-gray-300 rounded-lg max-h-60 flex flex-col gap-2 overflow-auto shadow-lg"
      style="margin-top: 6px"
    >
      <div
        v-for="(option, index) in filteredOptions"
        :key="index"
        @click="selectOption(option)"
        class="cursor-pointer px-4 py-2"
      >
        <span class="flex w-auto cursor-pointer z-50 gap-1">
          <span class="flex">
            <checkbox
              checkbox-size="height:16px; width:16px; margin-top: 5px; cursor: pointer"
              v-model="option.checked"
               @change="selectOption(option)"
            />
          </span>
          <span
            class="text-sm flex flex-col justify-center"
            style="margin-top: 3px"
            >{{ option.name }}</span
          >
        </span>
      </div>

      <!-- No options available message -->
      <div class="mt-1 flex flex-col gap-3 px-4 py-4">
        <div class="flex w-auto gap-2">
          <icon icon-name="icon-plus" class="text-flame" size="xms" />
          <p class="text-sm text-flame font-semibold">Add Custom</p>
        </div>

        <div class="flex w-full flex-col gap-5">
          <divider class="my-1" />
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            v-model="customTag"
            style="height: 40px !important"
          />
          <div class="flex flex-end justify-end gap-3">
            <span
              class="flex w-auto gap-1 cursor-pointer"
              @click="$emit('addTag', customTag)"
            >
              <p class="text-sm font-semibold mx-1 text-flame">Save</p>
            </span>
            <span class="w-auto cursor-pointer" @click="[isOpen = false, searchQuery = '', customTag = '']">
              <p class="text-sm font-semibold mx-1">Cancel</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Checkbox from "./Checkbox";
import Divider from "./divider";

export default {
  name: "ComboBox",
  components: { CText, Checkbox, Divider },
  props: {
    options: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      addCustom: false,
      searchQuery: "", // Input field value
      selectedOption: null, // Selected option
      isOpen: false, // Dropdown visibility
      customTag: "",
    };
  },
  computed: {
    selectedTags() {
      return this.options.filter((tag) => tag.checked);
    },
    filteredOptions() {
      return this.options
    }
  },
  watch: {
    // Watch searchQuery and filter options automatically when searchQuery changes
    searchQuery() {
      this.filterOptions();
    },
  },

  methods: {
    // Filter options based on the search query
    filterOptions() {
      if (this.searchQuery === "") {
        this.filteredOptions = this.options;
      } else {
        this.filteredOptions = this.options.filter((option) =>
          option.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    // Handle selecting an option from the dropdown
    selectOption(option) {
      if (option.checked) {
        option.checked = false;
      this.$emit("removeTag", option.id);
      } else {
        option.checked = true;
      this.$emit("addSelectedTags", option);
      }
    },

    removeTag(tag) {
      this.$emit("removeTag", tag.id);
      tag.checked = false;
    },

    handleClickOutside(event) {
      if (this.$refs.combobox && !this.$refs.combobox.contains(event.target)) {
        this.isOpen = false;
      }
    },
  },

  mounted() {
    // Add a global click event listener
    document.addEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.cloud_input {
  border: 1px solid #878e99;
  min-height: 40px;
  padding: 8px 15px;
  width: 100%;
  border-radius: 5px;
  color: #321c3b;
  line-height: 120%;
  letter-spacing: 0.015em;
  outline: none;
}
div[contenteditable="true"]:focus {
  outline: none;
}
.tag {
  padding: 4px 12px;
  background-color: #f1f1f1;
}
</style>
